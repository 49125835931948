@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.numbered-list { 
  font-family: 'Space Mono', monospace; 
  border: 1px solid white;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.numbered-list * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  letter-spacing: -0.25px;
}

.numbered-list p {
  text-align: left;
}

.numbered-list ol { 
  padding-left: 50px; 
}

.numbered-list li { 
  color: #F96E46; 
  padding-left: 16px;
  margin-top: 24px;
  position: relative;
  font-size: 16px;
  line-height: 20px;
}

.numbered-list li:before {
  content: '';
  display: block;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 2px solid #ddd;
  position: absolute;
  top: -12px;
  left: -46px;
}

.numbered-list p {
  color: #998888; 
}

.numbered-list strong { 
  color: #00FFFF;
} 

.numbered-list ol.alternating-colors li:nth-child(odd):before { 
  border-color: rgba(243, 231, 238, 0.75);
}

.numbered-list ol.alternating-colors li:nth-child(even):before { 
  border-color: rgba(243, 231, 238, 0.75);
}

@media (max-width: 616px) 
{
  .numbered-list ol {
    padding-left: 30px; 
  }

  .numbered-list li:before {
    border-style: none;
  }
}