
@include keyframes(cursor-blink) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.a-404-page {
  background-color: black;
  width: 100%;
  height: 100%;
  color: #1ff042
}

@keyframes blinking-cursor {
  50% {
    opacity: 0;
  }
}

.four-oh-four {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  background-color: black;
  transition: opacity 300ms ease-out;
  background-position: center center;
  background-repeat: no-repeat;
  .dJAX_internal{
    opacity: 0.0;
  }

  form, input{
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: black;
  }
}

.terminal{
  position: relative;
  padding: 4rem;

  .prompt{
    color: #1ff042;
    display: block;
    font-family: 'AndaleMono', monospace;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.15em;
    white-space: pre-wrap;
    text-shadow: 0 0 2px rgba(#1ff042,0.75);
    line-height: 1;
    margin-bottom: 0.75em;

    &:before {
      content: '> ';
      display: inline-block;
    }
  }

  .new-output{
    display: inline-block;
    &:after {
      animation: blinking-cursor 1s linear infinite;
      display: inline-block;
      vertical-align: -0.15em;
      width: 0.75em;
      height: 1em;
      margin-left: 5px;
      background: #1ff042;
      box-shadow: 1px 1px 1px rgba(#1ff042,0.65), -1px -1px 1px rgba(#1ff042,0.65), 1px -1px 1px rgba(#1ff042,0.65), -1px 1px 1px rgba(#1ff042,0.65);
      @include animation(cursor-blink 1.25s steps(1) infinite);
      content: '';
    }
  }
}

.four-oh-four-form{
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
}