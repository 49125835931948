@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*----------------------------------------------------------------------------------------*/
/* Glowing Orb CSS
/*----------------------------------------------------------------------------------------*/

.scrolldown-button-title {
  font-family: 'Space Mono', monospace !important; 
  user-select: none;
}

.glowing-orb {
  width: 350px;
  height: 350px;
  margin: 0 auto;
  position: absolute;
  left: -54px;
  right: 0;
}

.glowing-orb .outside-circle {
  width: 304px;
  height: 304px;
  margin: 47px;
  background: rgba(238, 66, 102, 1);
  z-index: 2;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
}

.glowing-orb .inside-circle {
  width: 242px;
  height: 242px;
  border-radius: 50%;
  position: absolute;
  top: 78px;
  left: 78px;
  z-index: 5;
  display: table;
  background: rgba(0, 0, 0, 0.79);
}

.inside-circle:hover {
  cursor: pointer;
  background: #00DB86;
}

.inside-circle:hover + .outside-circle {
  background: #1AC8ED;
}

.glowing-orb .inside-circle:before {
  width: 232px;
  height: 232px;
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.glowing-orb .inside-circle i.fa {
  font-size: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #006064;
}

.glow .hinge {
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  animation-iteration-count: infinite;
  transition-timing-function: ease-out;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
    -webkit-transform: scale3d(.8, .8, .8);
            transform: scale3d(.8, .8, .8);
  }
  
  100% {
    opacity: 0.3;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.25;
    -webkit-transform: scale3d(.8, .8, .8);
            transform: scale3d(.8, .8, .8);
  }
  
  100% {
    opacity: 0.5;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}

/*----------------------------------------------------------------------------------------*/
/* Animated Arrows CSS 
/*----------------------------------------------------------------------------------------*/

.indicator {
  position:relative;
  width:13px;
  height:13px;
  transform:rotate(45deg);
  opacity: 0.4;
  top: -21px;
  left: 1px;
}

.indicator > span{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    box-sizing:border-box;
    border:none;
    border-color:white;
    border-bottom:2px solid #fff;
    border-right:2px solid #fff;
    animation:scrolldown-motion 1s linear infinite;
}

.indicator > span:nth-child(1){
  top:-30px;
  left:-30px;
  animation-delay:0s;
}

.indicator > span:nth-child(2){
  top:-15px;
  left:-15px;
  animation-delay:0.2s;
}

.indicator > span:nth-child(3){
  top:0;
  left:0;
  animation-delay:0.4s;
}

.indicator > span:nth-child(4){
  top:15px;
  left:15px;
  animation-delay:0.6s;
}

.indicator > span:nth-child(5){
  top:30px;
  left:30px;
  animation-delay:0.8s;
}

@keyframes scrolldown-motion {
  0%{
    border-color:white;
    transform:translate(0,0);
  }
   20%{
    border-color:white;
     transform:translate(15px,15px);
  }
   20.1%,100%{
    border-color:white;
  }
}