
.signup-step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;
}

.signup-step-header {
  text-align: center;
  padding-top: 4px;  
  font-weight: bold;
  color: white;
  font-size: 1.2em;
}

.signup-step-header h4 {
  padding-bottom: 8px;
  margin-top: 20px;
  font-size: 0.9em;
  margin-top: 16px;
  margin-bottom: 0px;
  padding-left: 32px;
  padding-right: 32px;
}

.signup-step-header h2 {
  margin: 0px;
}

.signup-input-card {
  width: 600px;
  padding: 8px;
  padding-bottom: 12px;
  margin-left: 8px;
  margin-right: 8px;
}

.StepperContainer-0-2-1 {
  padding-bottom: 50px !important;
}

@media (max-width: 616px) {
  .signup-input-card {
    width: calc(100% - 16px) !important;
  }

  .StepperContainer-0-2-1 {
    padding-bottom: 28px !important;
  }
}

.signup-input-card .back-arrow {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  color: white;
  width: 20px;
  height: 20px;
}

.signup-input-card button {
    font-family: 'Space Mono', monospace; 
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 4px;
}

.signup-input-column {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  margin-left: 8px;
  width: calc(100% - 16px);
}

.signup-input-card .contact-form {
  padding-top: 20px;
}

