/*** ContactView.css ***/

.contact-card {
  boxShadow: 5;
  color: white;
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 12px !important;
}

.contact-card .contact-form {
  width: 800px;
}

@media (max-width: 616px) 
{
  .contact-card {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 120px;
    margin-bottom: 120px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (max-width: 816px) 
{
  .contact-card .contact-form {
    width: 100%;
  }
}


.contact-card button {
  height: 50px;
  width: 100%;
  borderRadius: 10px;
  font-size: 1.6;
  font-weight: bold;
}
