/*** CircularMenu.css ***/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap');

.circular-menu {
  position: relative;
  z-index: 100;
}

.circular-menu .pie {
  -webkit-tap-highlight-color: transparent;
  background:  #00DB86;
  border-radius: 50%;
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 400px;
  left: -200px;
  position: absolute;
  top: -200px;
  width: 400px;
  transform: translateX(-200px) translateY(-200px);
  transition: transform 300ms;
  border-color: #00DB86;
  border-style: solid;
  border-width:  2px;

}

.circular-menu .pie-color:hover {
  opacity: 0.85;
}

.circular-menu .pie-color:active {
  opacity: 0.7;
}

.circular-menu .pie1 {
  clip-path: polygon(200px 200px, 344px 450px, 0 450px);
  transition-delay: 30ms;
}

.circular-menu .pie2 {
  clip-path: polygon(200px 200px, 344px 450px, 450px 344px);
  transition-delay: 60ms;
}

.circular-menu .pie3 {
  clip-path: polygon(200px 200px, 450px 0, 450px 344px);
  transition-delay: 90ms;
}


.circular-menu .pie-color {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.circular-menu .pie-color1 {
  background: linear-gradient(135deg, black, black);
  clip-path: polygon(200px 200px, 344px 450px, 0 450px);
}

.circular-menu .pie-color2 {
  background: linear-gradient(135deg, black, black);
  clip-path: polygon(200px 200px, 344px 450px, 450px 344px);
}
.circular-menu .pie-color3 {
  background: linear-gradient(135deg, black, black);
  clip-path: polygon(200px 200px, 450px 0, 450px 344px);
}

.circular-menu .pie {
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.circular-menu .menu-title {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.circular-menu .portfolio-title {
  position: absolute;
  left: 213px;
  top: 371px;
  width: 64px;
  transform: rotate(-14deg);
  -webkit-transform: rotate(-14deg);
  -moz-transform: rotate(-14deg);
  -ms-transform: rotate(-14deg);
  color: #EE4266;
}

.circular-menu .contact-title {
  position: absolute;
  left: 305px;
  top: 328px;
  width: 46px;
  transform: rotate(-44deg);
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  color: #EE4266;
}

.circular-menu .tutoring-title {
  position: absolute;
  left: 357px;
  top: 243px;
  width: 46px;
  transform: rotate(-77deg);
  -webkit-transform: rotate(-77deg);
  -moz-transform: rotate(-77deg);
  -ms-transform: rotate(-77deg);
  color: #EE4266;
}

.circular-menu .menu {
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 200px;
  left: -101px;
  position: absolute;
  top: -100px;
  width: 200px;
  background-color: black;
}

.circular-menu .hamburger {
  cursor: pointer;
  position: relative;
  left: 58%;
  top: 58%;
  width: 46px;
  height: 46px;
}

.circular-menu .active .menu {
  border-color: #00DB86;
  border-style: solid;
  border-width:  1px;
  transition-delay: 90ms;
}

.circular-menu .title {
  font-family: "Crimson Text", serif;
  font-size: 80px;
  line-height: 84px;
  margin-top: 60px;
  text-align: center;
}

.circular-menu .body {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 28px;
  margin: 30px auto;
  max-width: 600px;
  text-align: center;
}

.circular-menu .hamburger path {
  transition: transform 300ms;
}

.circular-menu .hamburger path:nth-child(1) {
  transform-origin: 25% 29%;
}

.circular-menu .hamburger path:nth-child(2) {
  transform-origin: 50% 50%;
}

.circular-menu .hamburger path:nth-child(3) {
  transform-origin: 75% 72%;
}

.circular-menu .hamburger path:nth-child(4) {
  transform-origin: 75% 29%;
}

.circular-menu .hamburger path:nth-child(5) {
  transform-origin: 25% 72%;
}

.circular-menu .active .pie {
  transform: translateX(0) translateY(0);
}

.circular-menu .active .hamburger path:nth-child(1) {
  transform: rotate(45deg);
}

.circular-menu .active .hamburger path:nth-child(2) {
  transform: scaleX(0);
}

.circular-menu .active .hamburger path:nth-child(3) {
  transform: rotate(45deg);
}

.circular-menu .active .hamburger path:nth-child(4) {
  transform: rotate(-45deg);
}

.circular-menu .active .hamburger path:nth-child(5) {
  transform: rotate(-45deg);
}

.navigation-button {
  text-decoration: none;
  color: #823329;
}

/*@media (max-width: 616px) 
{
  .circular-menu .hamburger {
    width: 30px;
    height: 30px;
    left: 55px;
    top: 56px;
  }

  .circular-menu .menu {
    width: 104px;
    height: 104px;
    top: -50px;
    left: -50px;
  }

}
*/