
.backdrop:before, .backdrop:after {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.backdrop {
  background-size: auto;
  background-repeat: repeat-y;
  -webkit-background-size: cover;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-position: center top;
}


