/*** PortfolioView.css ***/

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

#portfolio-header #hi {
    font-family: 'Quicksand', 'Bold 700', sans-serif;
    font-weight: 700;
    font-size: 5em;
    padding-right: 22px;
    user-select: none;
}

#portfolio-header #wave-emoji {
    padding-left: 20px;
}

#portfolio-header #reach-me {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    user-select: none;
}

#portfolio-header #my-email {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
}

#portfolio-body-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
}

.Skill-Grid-Item {
    border-radius: 4px;
    border: 2px solid #92eac0;
    padding: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-family: Camphor,Open Sans,Segoe UI,sans-serif;
    font-size: .85rem;
    color: #C9D6EA;
    background-color: rgba(0, 0, 0, 0.6);
}
